exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-1-szazalek-izeltlabuak-hu-js": () => import("./../../../src/pages/1-szazalek-izeltlabuak-hu.js" /* webpackChunkName: "component---src-pages-1-szazalek-izeltlabuak-hu-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informaciok-2021-eves-beszamolo-melleklet-contentonly-js": () => import("./../../../src/pages/informaciok/2021-eves-beszamolo-melleklet-contentonly.js" /* webpackChunkName: "component---src-pages-informaciok-2021-eves-beszamolo-melleklet-contentonly-js" */),
  "component---src-pages-informaciok-2021-eves-beszamolo-melleklet-js": () => import("./../../../src/pages/informaciok/2021-eves-beszamolo-melleklet.js" /* webpackChunkName: "component---src-pages-informaciok-2021-eves-beszamolo-melleklet-js" */),
  "component---src-pages-informaciok-2022-eves-beszamolo-melleklet-contentonly-js": () => import("./../../../src/pages/informaciok/2022-eves-beszamolo-melleklet-contentonly.js" /* webpackChunkName: "component---src-pages-informaciok-2022-eves-beszamolo-melleklet-contentonly-js" */),
  "component---src-pages-informaciok-2022-eves-beszamolo-melleklet-js": () => import("./../../../src/pages/informaciok/2022-eves-beszamolo-melleklet.js" /* webpackChunkName: "component---src-pages-informaciok-2022-eves-beszamolo-melleklet-js" */),
  "component---src-pages-informaciok-2023-eves-beszamolo-melleklet-contentonly-js": () => import("./../../../src/pages/informaciok/2023-eves-beszamolo-melleklet-contentonly.js" /* webpackChunkName: "component---src-pages-informaciok-2023-eves-beszamolo-melleklet-contentonly-js" */),
  "component---src-pages-informaciok-2023-eves-beszamolo-melleklet-js": () => import("./../../../src/pages/informaciok/2023-eves-beszamolo-melleklet.js" /* webpackChunkName: "component---src-pages-informaciok-2023-eves-beszamolo-melleklet-js" */),
  "component---src-pages-informaciok-alapadatok-js": () => import("./../../../src/pages/informaciok/alapadatok.js" /* webpackChunkName: "component---src-pages-informaciok-alapadatok-js" */),
  "component---src-pages-informaciok-dokumentumok-js": () => import("./../../../src/pages/informaciok/dokumentumok.js" /* webpackChunkName: "component---src-pages-informaciok-dokumentumok-js" */),
  "component---src-pages-informaciok-js": () => import("./../../../src/pages/informaciok.js" /* webpackChunkName: "component---src-pages-informaciok-js" */),
  "component---src-pages-informaciok-kapcsolat-js": () => import("./../../../src/pages/informaciok/kapcsolat.js" /* webpackChunkName: "component---src-pages-informaciok-kapcsolat-js" */),
  "component---src-pages-tajekoztatok-adatkezeles-jatek-onkentes-termeszeti-adatgyujtok-talalkozoja-js": () => import("./../../../src/pages/tajekoztatok/adatkezeles-jatek-onkentes-termeszeti-adatgyujtok-talalkozoja.js" /* webpackChunkName: "component---src-pages-tajekoztatok-adatkezeles-jatek-onkentes-termeszeti-adatgyujtok-talalkozoja-js" */),
  "component---src-pages-tajekoztatok-adatkezeles-js": () => import("./../../../src/pages/tajekoztatok/adatkezeles.js" /* webpackChunkName: "component---src-pages-tajekoztatok-adatkezeles-js" */),
  "component---src-pages-tajekoztatok-adatkezeles-onkentes-js": () => import("./../../../src/pages/tajekoztatok/adatkezeles-onkentes.js" /* webpackChunkName: "component---src-pages-tajekoztatok-adatkezeles-onkentes-js" */),
  "component---src-pages-tajekoztatok-aszf-izeltlabuak-hu-js": () => import("./../../../src/pages/tajekoztatok/aszf-izeltlabuak-hu.js" /* webpackChunkName: "component---src-pages-tajekoztatok-aszf-izeltlabuak-hu-js" */),
  "component---src-pages-tajekoztatok-aszf-jatek-onkentes-termeszeti-adatgyujtok-talalkozoja-js": () => import("./../../../src/pages/tajekoztatok/aszf-jatek-onkentes-termeszeti-adatgyujtok-talalkozoja.js" /* webpackChunkName: "component---src-pages-tajekoztatok-aszf-jatek-onkentes-termeszeti-adatgyujtok-talalkozoja-js" */),
  "component---src-pages-tajekoztatok-aszf-onkentes-js": () => import("./../../../src/pages/tajekoztatok/aszf-onkentes.js" /* webpackChunkName: "component---src-pages-tajekoztatok-aszf-onkentes-js" */),
  "component---src-pages-tajekoztatok-aszf-tamogatok-js": () => import("./../../../src/pages/tajekoztatok/aszf-tamogatok.js" /* webpackChunkName: "component---src-pages-tajekoztatok-aszf-tamogatok-js" */),
  "component---src-pages-tajekoztatok-etikai-kodex-js": () => import("./../../../src/pages/tajekoztatok/etikai-kodex.js" /* webpackChunkName: "component---src-pages-tajekoztatok-etikai-kodex-js" */),
  "component---src-pages-tajekoztatok-jogi-nyilatkozat-js": () => import("./../../../src/pages/tajekoztatok/jogi-nyilatkozat.js" /* webpackChunkName: "component---src-pages-tajekoztatok-jogi-nyilatkozat-js" */),
  "component---src-pages-tajekoztatok-js": () => import("./../../../src/pages/tajekoztatok.js" /* webpackChunkName: "component---src-pages-tajekoztatok-js" */),
  "component---src-pages-tajekoztatok-suti-js": () => import("./../../../src/pages/tajekoztatok/suti.js" /* webpackChunkName: "component---src-pages-tajekoztatok-suti-js" */),
  "component---src-pages-tamogatas-js": () => import("./../../../src/pages/tamogatas.js" /* webpackChunkName: "component---src-pages-tamogatas-js" */)
}

